<template>
  <div class="table">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 尺码配置
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-button
          type="success"
          icon="el-icon-plus"
          class="handle-add mr10"
          @click="onAdd"
        >
          新建
        </el-button>
        <el-button
          type="danger"
          icon="el-icon-delete"
          class="handle-del mr10"
          @click="delAll"
        >
          批量删除
        </el-button>
        <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
          刷新
        </el-button>
        <el-cascader
          v-model="select_word"
          class="mr10"
          :options="typeList.options"
          :show-all-levels="false"
          :props="{
            value: 'id',
            label: 'name',
            children: 'childrensList',
            emitPath: false
          }"
          placeholder="请选择备装类型"
        >
        </el-cascader>
        <el-button type="primary" icon="el-icon-search" @click="search">
          搜索
        </el-button>
        <el-button icon="el-icon-reset" @click="reset">重置</el-button>
      </div>
      <el-table
        v-loading="listLoading"
        :data="list"
        element-loading-text="正在加载 ..."
        border
        fit
        highlight-current-row
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column v-if="false" label="备装类型" prop="type" />
        <el-table-column label="备装类型" prop="typeName" />
        <el-table-column label="身高" prop="height" />
        <el-table-column label="腰围" prop="waist" />
        <el-table-column label="臀围" prop="hipline" />
        <el-table-column label="码数" prop="size" />
        <el-table-column label="裤长" prop="pant" />
        <el-table-column label="肩宽" prop="shoulder" />
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="onEdit(scope.row)">
              编辑
            </el-button>
            <el-button type="danger" size="small" @click="onDel(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageInfo.startPage"
          :page-sizes="[10, 20, 30, 50, 100, 200]"
          :page-size="pageInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total"
          @size-change="onSizeChange"
          @current-change="onPageChange"
        />
      </div>
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog
      title="编辑尺码配置信息"
      width="600px"
      :visible.sync="selectDlgShow"
    >
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="备装类型">
            <div class="block">
              <el-cascader
                v-model="form.type"
                :options="typeList.options"
                :show-all-levels="false"
                :props="{
                  value: 'id',
                  label: 'name',
                  children: 'childrensList',
                  emitPath: false
                }"
              >
              </el-cascader>
            </div>
          </el-form-item>
          <el-form-item label="身高">
            <el-input v-model="form.height" type="number" />
          </el-form-item>
          <el-form-item label="腰围">
            <el-input v-model="form.waist" type="text" />
          </el-form-item>
          <el-form-item label="臀围">
            <el-input v-model="form.hipline" type="text" />
          </el-form-item>
          <el-form-item label="码数">
            <el-input v-model="form.size" type="text" />
          </el-form-item>
          <el-form-item label="裤长">
            <el-input v-model="form.pant" type="number" />
          </el-form-item>
          <el-form-item label="肩宽">
            <el-input v-model="form.shoulder" type="number" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDlgShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveSize,
  getSizeList,
  getSizeInfo,
  delSize,
  getAllTypeList
} from '@/api/equipment';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';

export default {
  name: 'EquipmentSize',
  data() {
    return {
      select_word: 0,
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        type: 0,
        sorting: {},
        total: 0
      },
      list: [],
      typeList: { options: [] },
      form: {
        id: '',
        type: '',
        height: '',
        waist: '',
        hipline: '',
        size: '',
        pant: '',
        shoulder: ''
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    //初始化数据
    this.fetchData();
    this.typeList.options = [];
    getAllTypeList().then(response => {
      this.typeList.options = response.data;
    });
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.pageInfo.type = this.select_word;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
    },
    onAdd() {
      this.typeList.options = [];
      getAllTypeList().then(response => {
        this.typeList.options = response.data;
      });
      this.form = {
        type: '',
        height: '',
        waist: '',
        hipline: '',
        size: '',
        pant: '',
        shoulder: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        type: row.type,
        height: row.height,
        waist: row.waist,
        hipline: row.hipline,
        size: row.size,
        pant: row.pant,
        shoulder: row.shoulder
      };
      this.selectDlgShow = true;
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delSize({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    onDel(row) {
      this.$confirm('此操作将永久删除尺寸配置数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delSize({ id: row.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    fetchData() {
      this.listLoading = true;
      getSizeList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    save() {
      this.dlgLoading = true;
      saveSize(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    reset() {
      this.select_word = 0;
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 5px;
}
</style>
